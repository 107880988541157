var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',[_c('WithLocation',{attrs:{"slug":_vm.locSlug},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var isLocationLoading = ref.isLocationLoading;
    var theLocation = ref.theLocation;
    var theFeaturedImage = ref.theFeaturedImage;
    var notFound = ref.notFound;
return [(isLocationLoading)?_c('BaseLoader',{attrs:{"bg-color":"#fff","color":"#6a8ed1"}},[_c('h3',{staticClass:"text-xs-center mt-4 bloak-light-blue--text"},[_vm._v("Loading the menu...")])]):(!theLocation || notFound)?_c('div',{staticClass:"not-found"},[_c('VContainer',{staticClass:"bloak-light-blue",staticStyle:{"padding-top":"80px !important","padding-bottom":"80px !important"},attrs:{"fluid":"","px-0":"","pa-3":""}},[_c('VLayout',{attrs:{"justify-center":""}},[_c('VFlex',{attrs:{"xs11":"","sm8":""}},[_c('h2',{staticClass:"header-1"},[_vm._v("Sorry, but we couldn't find that location!")]),_c('h3',{staticClass:"body"},[_vm._v("Please head to our homepage or contact us if you need assistance.")])])],1)],1)],1):_c('div',{staticClass:"single-location"},[_c('BaseThemeComponent',{attrs:{"name":"SingleLocationHeader","bg-image":theFeaturedImage ? theFeaturedImage : '',"title":theLocation.title,"location":theLocation}}),(theLocation.status !== 'coming_soon')?_c('BasePageSection',{staticClass:"blue-oak-menu-section",attrs:{"id":"menu","py-2":"","fluid":"","full-width":""}},[_c('VLayout',{attrs:{"row":"","justify-center":""}},[_c('VFlex',{attrs:{"xs12":""}},[_c('BaseLocationContent',{attrs:{"content-key":"menu_title","default-content":"Our Menu","tag-class":"header-4 text-xs-center mb-4 bloak-blue--text","tag":"h1"}})],1)],1),_c('WithLayout',{attrs:{"slug":"blue-oak-hsv-web-menu"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var isLayoutLoading = ref.isLayoutLoading;
    var theLayout = ref.theLayout;
return [(!isLayoutLoading)?_c('LayoutGrid',{staticClass:"blue-oak-web-menu",attrs:{"location":theLocation,"layout":theLayout}}):_vm._e()]}}],null,false,3426983029)})],1):_vm._e(),_c('BaseThemeComponent',{attrs:{"name":"PageSection"}},[_c('VLayout',{attrs:{"row":"","justify-center":""}},[_c('VFlex',{attrs:{"xs12":"","md8":""}},[_c('BaseLocationContent',{attrs:{"content-key":"cta_title","default-content":"Call Now to Place Your Order!","tag-class":"header-4 bloak-section--title","tag":"h1"}}),_c('h3',{staticClass:"header-2 text-xs-left pt-3"},[_c('a',{attrs:{"href":("tel:" + (_vm.formatPhone('(256) 900-7427')))}},[_vm._v("256-900-7427 (RIBS)")])])],1)],1)],1),_c('BaseThemeComponent',{attrs:{"name":"CateringCTA"}}),_c('BaseThemeComponent',{attrs:{"name":"SectionLocations"}})],1)]}}])}),_c('BaseMenuComponent',{attrs:{"name":"MenuSnackbar"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }